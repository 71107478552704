import Subhub from '../../components/Hubs/Subhub/Subhub'
import QML_hub from '../../content/qml/hubs/QML_hub'
import Seo from '../../components/seo'

const { meta_title, meta_description, meta_image, top_section, video_section } =
  QML_hub

const QMLHub = () => {
  return <Subhub top_section={top_section} video_section={video_section} />
}

export default QMLHub

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
