export default {
  meta_title: 'Quantum Machine Learning | PennyLane',
  meta_description:
    'Sit back and learn about the field of quantum machine learning, explore key concepts, download and run QML demonstrations, and view our selection of curated videos.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumMachineLearning/QMLOpenGraph.png',
  top_section: {
    title: 'Quantum Machine Learning',
    sub_title_1:
      "We're entering an exciting time in quantum physics and quantum computation: near-term quantum devices are rapidly becoming a reality, accessible to everyone over the internet.",
    sub_title_2:
      'This, in turn, is driving the development of quantum machine learning and variational quantum circuits.',
    link_cards: [
      {
        link: '/qml/whatisqml',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/QML.svg',
        alt: '',
        title: 'What is QML?',
        description:
          'Find out how the principles of quantum computing and machine learning can be united to create something new.',
        linkText: 'Read more',
      },
      {
        link: '/qml/glossary',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/glossary_icon.png',
        alt: '',
        title: 'Key Terms',
        description:
          'Explore different concepts underpinning variational quantum circuits and quantum machine learning.',
        linkText: 'Read more',
      },
      {
        link: '/qml/demonstrations',
        icon: 'https://assets.cloud.pennylane.ai/pennylane_website/icons/demos_icon.png',
        alt: '',
        title: 'Demos',
        description:
          'Take a dive into quantum machine learning by exploring cutting-edge algorithms on near-term quantum hardware.',
        linkText: 'Read more',
      },
    ],
  },
  video_section: {
    sub_title:
      'Sit back and explore quantum machine learning with our curated selection of expert videos.',
    img_alt: 'Optimize a Quantum Circuit thumbnail',
    title: 'Optimize a Quantum Circuit',
    image: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/qml/quantumMachineLearning/YTQuantumCircuit.png',
    videoId: '42aa-Ve5WmI',
  },
}
